import React, { useState } from "react";
import { Table } from "../../controls/Table/Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { getReport } from "../../../helpers/ReportingHelper";
import { exportWordPlanUrl } from "../../../common/constants";

interface IRepaymentPlanDetail {
  rows: [];
}

export const RepaymentPlanResponse: React.FC<IRepaymentPlanDetail> = ({
  rows,
}: IRepaymentPlanDetail) => {
  const storeState = useSelector((state: RootState) => state.reducerRentalAPR);
  const [enableExport, setEnableExport] = useState(true);
  const GenerateReport = () => {
    var requestObject = {
      financeAmount: storeState.financedAmount,
      currency: "EUR",
      interestRate: storeState.APR == "" ? 0 : storeState.APR,
      Planitems: [],
    };
    storeState.repaymentPlanApiResponse.repaymentPlan.map((rental: any) => {
      requestObject.Planitems.push({
        rentalNumber: rental.rentalNumber,
        openingPrincipal: rental.openingPrincipal,
        rentalAmount: rental.rentalAmount,
        rentalPrincipal: rental.rentalPrincipal,
        rentalInterest: rental.rentalInterest,
        rentalDueDate: rental.rentalDueDate,
        periodicInterest: rental.periodicInterest,
        closingPrincipal: rental.closingPrincipal,
      });
    });
    setEnableExport(false);
    getReport(exportWordPlanUrl, requestObject, "Repayment_plan")
      .then(() => {
        setEnableExport(true);
      })
      .catch(() => {
        setEnableExport(true);
      });
  };
  const columns = [] as any;
  columns.push("Rental #");
  columns.push("Rental DueDate");
  columns.push("Opening Principal");
  columns.push("Rental Amount");
  columns.push("Principal");
  columns.push("Interest");
  columns.push("Periodic Interest");
  columns.push("Closing Principal");
  if (rows.length > 0) {
    return (
      <Table
        columns={columns}
        rows={rows}
        showExport
        fileName={"Repayment Plan"}
      />
    );
  } else {
    return <div></div>;
  }
};
