import axios from "axios";
import { getToken } from "./Localstorage";
const baseUrl = `${process.env.REPORTING_URL}`;
const getReport = async (
  url: string,
  requestBody: any,
  fileName: string,
  checkWord: boolean = false
) => {
  let response = undefined;
  await axios
    .post(baseUrl + url, requestBody, {
      headers: {
        Authorization: "Bearer " + getToken().access_token,
        client_name:
          getToken().company_name != undefined
            ? getToken().company_name
            : "Netsol",
      },
    })
    .then((response) => {
      const href = checkWord
        ? response.data.generated_document_url
        : response.data;
      // create "a" HTLM element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileName}_${new Date()}.docx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

export { getReport };
